// start_ai_generated
import { useMemo } from 'react'
import { Picture, Text, Link } from '@components/ui'
import { formatLink, refLink } from '../utils'
import cn from 'classnames'
import Button from '@components/home/Button'
import useCouponInfo from '@components/product/ProductView/useCouponInfo'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import s from '../Home.module.css'
import { useRouter } from 'next/router'
import { handleLocaleLink } from '@lib/utils/tools'
import useProduct from '@shopify/product/use-product'
import { atobID } from '@shopify/utils'
import { useCoupon } from '@commerce/product/use-coupon'
import useProducts from '@shopify/product/use-products'
import { features } from 'commerce.config'

const Product = ({ product, idx, shopCommon, locale, title }) => {
  const { pathname } = useRouter()

  const isHome = useMemo(() => pathname === '/', [pathname])

  const handles = useMemo(() => {
    return (
      product.metafields.productData?.custom_variants?.variants?.map(
        (item) => item.handle
      ) ||
      product.metafields.custom_variants?.url?.map((item) =>
        item.replace('/products/', '')
      ) ||
      product.metafields.custom_variants?.sku?.map((item) => item.toLowerCase())
    )
  }, [
    product.metafields.custom_variants?.sku,
    product.metafields.custom_variants?.url,
    product.metafields.productData?.custom_variants?.variants,
  ])
  const locales = useMemo(() => (features ? Object.keys(features) : ['us']), [])
  const { data: resultVariants } = useProducts(
    handles?.join(' OR '),
    locale,
    locales
  )

  const variants = useMemo(() => {
    return product.variants.length > 1
      ? product.variants
      : resultVariants?.products.filter((item) => item.availableForSale) || []
  }, [product.variants, resultVariants?.products])

  // 使用useMemo优化链接生成逻辑
  const href = useMemo(
    () =>
      isHome
        ? refLink(
            `/products${product.path}?variant=${atobID(product.variant?.id)}`,
            title,
            `buynow_${idx + 1}`
          )
        : formatLink(
            `/products${product.path}?variant=${atobID(product.variant?.id)}`,
            'ref',
            'homepage_bestprice',
            idx
          ),
    [isHome, product.path, product.variant?.id, title, idx]
  )

  // 使用useMemo优化本地化链接处理
  const realLink = useMemo(
    () => handleLocaleLink({ href, locale }),
    [href, locale]
  )

  const { coupon } = useCoupon({ variant: product.variant })

  const { priceFormatted, discountPriceFormatted, discountText } =
    useCouponInfo({
      product,
      variant: product.variant,
      coupon,
      isDigits: false,
    })

  const selectItem = () => {
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group: product?.defaultSku
          ? `listing_bottom_${product?.defaultSku || ''}`
          : isHome
          ? 'Home_Page'
          : `Activity Page_${pathname}`,
        item_list_name: isHome ? 'Home_Page_Bundle' : 'listing_bottom_Bundle',
        items: [
          {
            item_id: product.defaultSku,
            item_name: product.title,
            item_variant: '',
            price: product.price?.value,
            index: idx + 1,
          },
        ],
      },
    })
  }

  return (
    <div className={cn('relative h-full rounded-xl bg-white', s.picBox)}>
      <div
        className={cn(
          'relative rounded-xl p-6',
          'min-md:p-4',
          'min-l:px-6 min-l:py-8',
          {
            'bg-[#f7f8f9]': !isHome,
          },
          {
            '!pb-0': isHome,
          }
        )}
      >
        <Link
          href={href}
          onClick={selectItem}
          className={cn({
            'mx-auto block w-[250px] min-l:w-[180px] min-xl:w-[250px]': isHome,
          })}
        >
          {discountText && (
            <span className="absolute left-4 top-4 z-[1] rounded-[20px] bg-[#B3ECFB] px-[10px] py-[5px] text-[14px] font-semibold text-brand-color">
              {discountText} {shopCommon.off}
            </span>
          )}
          <Picture
            source={product?.variant?.image?.url || product?.images[0].url}
            imgWidth={
              product?.variant?.image?.width || product?.images[0].width
            }
            imgHeight={
              product?.variant?.image?.height || product?.images[0].height
            }
            alt={product?.variant?.image?.altText || product?.images[0].altText}
            className="relative pt-[100%]"
            imgClassName=" absolute top-0 left-0 h-full"
          ></Picture>
        </Link>
      </div>

      <div
        className={cn('mt-1 pr-4 text-left', 'min-md:pr-5', 'min-l:pr-6', {
          '!px-6 pb-6': isHome,
        })}
      >
        <div className="flex min-h-[20px] flex-wrap gap-4">
          {product.tag && (
            <>
              {Array.isArray(product.tag) ? (
                <>
                  {product.tag.map((tag, index) => (
                    <Text
                      className={cn(
                        'relative mt-[6px] text-sm font-semibold text-[#FF4D4D] after:absolute after:right-[-8px] after:top-1/2 after:h-[11px] after:w-[1px] after:-translate-y-1/2 after:bg-[#e9e9e9] after:content-[""] last:after:hidden'
                      )}
                      key={index}
                    >
                      {tag}
                    </Text>
                  ))}
                </>
              ) : (
                <Text
                  className={cn(
                    'mt-[6px] text-sm font-semibold text-[#FF4D4D]'
                  )}
                >
                  {product.tag}
                </Text>
              )}
            </>
          )}
        </div>
        <Text
          className={cn(
            'mb-3 mt-1 min-h-[39px] text-[16px] font-bold',
            'min-l:min-h-[51px] min-l:text-[18px]',
            {
              '!font-semibold': isHome,
            }
          )}
        >
          <Link
            className="line-clamp-2 !no-underline"
            href={href}
            onClick={selectItem}
          >
            {product?.title}
          </Link>
        </Text>
        <div
          className={cn('flex items-end gap-x-3', {
            '!gap-x-1': isHome,
          })}
        >
          {product?.price?.value === 9999999.99 ? (
            <span className="text-[#777]">{shopCommon?.soldOut}</span>
          ) : (
            <>
              <span
                className={cn(
                  'text-[16px] font-medium tracking-[1px] min-xl:text-[18px]',
                  {
                    '!text-[32px] font-semibold leading-[1]': isHome,
                  }
                )}
              >
                {discountPriceFormatted || priceFormatted}
              </span>
              {discountPriceFormatted && (
                <span
                  className={cn(
                    'text-[16px] font-medium tracking-[1px] text-[#9f9f9f] line-through opacity-60 min-xl:text-[18px]',
                    {
                      'relative -top-1 !text-[16px] font-semibold leading-[1]':
                        isHome,
                    }
                  )}
                >
                  {priceFormatted}
                </span>
              )}
            </>
          )}
        </div>
        {isHome ? (
          <div className="mt-8 text-sm font-medium">
            {variants.length > 1 ? (
              <p
                className={cn({
                  'mb-2': product?.metafields?.productExtInfo,
                })}
              >
                <span>
                  {shopCommon.variant_options?.replace('$num', variants.length)}
                </span>
              </p>
            ) : null}
            {product?.metafields?.productExtInfo && (
              <p className="line-clamp-2 break-words">
                {product?.metafields?.productExtInfo.slice(0, 2).join(' | ')}
              </p>
            )}
          </div>
        ) : (
          <Button href={realLink} variant="link" onClick={selectItem}>
            {shopCommon.buyNow}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Product
// end_ai_generated
