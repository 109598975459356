/* eslint-disable no-unused-vars */
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/grid'
import cn from 'classnames'
import s from '../Home.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, Mousewheel } from 'swiper'
import { Text } from '@components/ui'
import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import Product from './product'
import { useRouter } from 'next/router'
import getProductPersonalizeBySkus from '@lib/apis/get-product-personalize-by-skus'
import getProductPersonalizeByUser from '@lib/apis/get-product-personalize-by-user'
import { useInView } from 'react-intersection-observer'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { NextArrow, PrevArrow } from '../V4/Components/SwiperArrow'
import { ArrowLeft, ArrowRight } from '@components/icons/home'
import { features } from 'commerce.config'
import useProducts from '@shopify/product/use-products'

const PopularProduct = ({
  locale,
  section,
  relatedProducts,
  shopCommon,
  variant = {},
  recom, // 是否是算法推荐商品
  /** recom是一个布尔值，表示是否是算法推荐的商品。在组件中，它被用来判断是否需要获取个性化推荐的商品数据。
/* 具体逻辑如下：
/* 如果recom为true，则调用getPersonalize函数获取个性化推荐的商品数据。
/* 如果recom为false，则调用couponCallback函数获取优惠券数据，并根据section和relatedProducts的值，过滤并格式化商品数据。
/* 需要注意的是，recom的值可以通过组件的props传入，或者默认为false
*/
}) => {
  const [swiperRef, setSwiperRef] = useState(null)
  const [progress, setProgress] = useState(0)
  const [products, setProducts] = useState([])
  const isRecom = recom || section?.__recom_type || false
  const title = isRecom ? shopCommon?.product_recom_title : section?.title
  const { pathname } = useRouter()
  const [originProducts, setOriginProducts] = useState([])
  const [sortArr, setSortArr] = useState([])

  useEffect(() => {
    const products = section?.blocks?.map((block) => {
      return {
        handle: block.__handle,
        sku: block.__sku,
        tag: block.tag,
      }
    })
    if (products?.length == 0) setOriginProducts(products)
  }, [originProducts?.length, section?.blocks])

  const locales = useMemo(() => (features ? Object.keys(features) : ['us']), [])
  const [handles, setHandles] = useState([])
  const [query, setQuery] = useState('')
  const [resultProducts, setResultProducts] = useState([])
  useEffect(() => {
    if (!originProducts || originProducts?.length == 0) return
    setHandles(originProducts.map((res) => res.handle))
    setQuery(
      `${originProducts
        ?.map((item) => {
          return item?.handle + '&' + item.sku
        })
        .join(' OR ')} NOT bundle`
    )
  }, [originProducts])
  const { products: result } = useProducts(query, locale, locales)
  useEffect(() => {
    if (result && result.length) {
      setResultProducts(() => {
        let arr = []
        for (let i = 0; i < originProducts.length; i++) {
          for (let j = 0; j < result.length; j++) {
            if (result[j]?.handle == originProducts[i]?.handle) {
              arr.push(result[j])
              continue
            }
          }
        }
        for (let i = 0; i < sortArr.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (arr[j]?.handle == sortArr[i].handle) {
              arr.splice(sortArr[i].sort - 1, 0, arr.splice(j, 1)[0])
            }
          }
        }
        return arr
      })
    }
  }, [originProducts, result])
  useEffect(() => {
    const skus = resultProducts?.map((item) => item.sku)
    if (resultProducts?.length && resultProducts?.length != 0) {
      resultProducts.forEach((product) => {
        let variant = {}
        product.variants?.forEach((v) => {
          if (skus.includes(v.sku)) {
            product.defaultSku = v.sku
            variant = v
          }
        })
        product.variant = variant
      })
      setProducts(resultProducts)
    }
  }, [resultProducts])

  const isHome = useMemo(() => pathname === '/', [pathname])

  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
  const [swiper, setSwiper] = useState()

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '120px',
    triggerOnce: true,
  })

  const trace = useRef(false)

  if (inView && !trace.current) {
    let items = []
    products.forEach((product, index) => {
      items.push({
        item_id: product.defaultSku,
        item_name: product.title,
        item_variant: '',
        price: product.price?.value,
        index: index + 1,
      })
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'view_item_list',
      event_parameters: {
        page_group: variant?.sku
          ? `listing_bottom_${variant?.sku || ''}`
          : isHome
          ? 'Home_Page'
          : `Activity Page_${pathname}`,
        item_list_name: isHome ? 'Home_Page_Bundle' : 'listing_bottom_Bundle',
        items,
      },
    })
    trace.current = true
  }

  // 优化：避免在每次组件渲染时创建新的数组
  /* const formatProducts = useMemo(() => {
    if (isRecom) return []
    return (
      section?.blocks
        ?.map((block) => {
          const target = relatedProducts?.find(
            (product) => product.origin.sku === block.__sku
          )
          if (target && Object.keys(target?.result).length > 0) {
            if (target?.result) {
              target.result.defaultSku = block.__sku
              target.result.tag = block.tag
            }
            return target?.result
          }
        })
        .filter((block) => block !== undefined)
        .filter((p) => p.availableForSale) || []
    )
  }, [isRecom, relatedProducts, section?.blocks]) */

  // 优化：避免不必要的数组操作
  /* useEffect(() => {
    formatProducts.length &&
      setProducts((prevProducts) => [...prevProducts, ...formatProducts])
  }, [formatProducts]) */
  const getPersonalize = useCallback(async () => {
    if (!isRecom) return

    const skus = [variant.sku]

    const res = isHome
      ? await getProductPersonalizeByUser({
          locale,
          limit: 20,
        })
      : await getProductPersonalizeBySkus({
          locale,
          skus,
          limit: 12,
          version: 'v2',
          recom_type: 'details',
        })

    if (res?.data) {
      const compressProducts = Array.isArray(res?.data)
        ? res?.data
        : Object.keys(res?.data).reduce((pre, cur) => {
            return pre && res?.data?.[cur] ? [...pre, ...res?.data?.[cur]] : []
          }, [])

      setOriginProducts(() => {
        // 首先按照ranking属性对数组进行排序
        const sortedByRanking = compressProducts.sort((a, b) => {
          return (b?.ranking || 0) - (a?.ranking || 0)
        })

        let finalSortedArray = sortedByRanking
        finalSortedArray.forEach((item) => {
          if (item?.sort) {
            setSortArr((prev) => {
              const newArr = [...prev, { sort: item.sort, handle: item.handle }]
              return [
                ...new Map(newArr.map((item) => [item.handle, item])).values(),
              ].sort((a, b) => a.sort - b.sort)
            })
          }
        })
        // 由于上面的操作可能会导致数组中的位置被多个元素占据，
        // 需要过滤掉undefined元素，并确保数组长度不超过原始数组长度
        finalSortedArray = finalSortedArray.filter((item) => item !== undefined)
        finalSortedArray.length = compressProducts.length

        return finalSortedArray
      })
    }
  }, [isRecom, variant.sku, isHome, locale])

  useEffect(() => {
    if (products.length == 0) {
      getPersonalize()
    }
  }, [getPersonalize, products])

  const handleProgress = (swiper) => {
    setStart(swiper.isBeginning)
    setEnd(swiper.isEnd)
  }

  return products.length ? (
    <div className={cn(s.section, 'overflow-hidden')} ref={ref}>
      <div
        className={cn('fullWidth fullPadOnlyWidth select-none md:!px-0', {
          '!max-w-[1920px] !px-0': isHome,
        })}
      >
        <Text
          className={cn(
            s.title,
            isHome
              ? 'mb-6 min-l:mb-8 min-xl:mb-12'
              : 'mb-9 min-md:mb-10 min-l:mb-[60px]'
          )}
          html={title}
        ></Text>
        <div
          className={cn('product-list relative min-l:px-[76px]', {
            '!px-0': isHome,
          })}
        >
          <Swiper
            onSwiper={(swiper) => {
              setSwiperRef(swiper)
              setProgress(Math.abs(swiper.progress))
              setSwiper(swiper)
            }}
            onProgress={(swiper) => {
              setProgress(Math.abs(swiper.progress))
              handleProgress(swiper)
            }}
            modules={[Grid, Mousewheel]}
            slidesPerView="auto"
            slidesPerGroup={1}
            mousewheel={{
              releaseOnEdges: true,
              forceToAxis: true,
            }}
            breakpoints={{ 1024: { allowTouchMove: false } }}
            className={cn(
              isHome ? '!overflow-visible' : '!pb-[24px] md:!pb-[36px]'
            )}
          >
            {products?.map(
              (product, idx) =>
                product.availableForSale && (
                  <SwiperSlide
                    key={idx}
                    className={cn(
                      'box-border !h-auto select-none',
                      isHome
                        ? '!ml-0 !w-[306px] !pr-2 min-l:!w-[315px] min-xl:!w-[456px] min-xl:last:!w-[448px]'
                        : '!w-[236px] pr-3 first:ml-6 min-md:pt-[6px] first:min-md:ml-10 min-l:!w-1/3 min-l:pr-4 first:min-l:ml-0 min-xl:!w-1/4'
                    )}
                  >
                    <Product
                      product={product}
                      idx={idx}
                      shopCommon={shopCommon}
                      locale={locale}
                      title={title}
                    />
                  </SwiperSlide>
                )
            )}
            <div
              className={cn({
                hidden: !isHome,
              })}
            >
              <PrevArrow swiper={swiper} start={start} end={end} />
              <NextArrow swiper={swiper} start={start} end={end} />
            </div>
          </Swiper>
          <div
            className={cn('hidden items-center justify-end gap-4 min-l:flex', {
              '!hidden': (start && end) || isHome,
            })}
          >
            <button
              onClick={() => swiperRef.slidePrev()}
              className={cn('z-[1] hidden h-12 w-12 text-[#333] min-l:block', {
                'cursor-not-allowed opacity-30': start,
                '!opacity-0': start && end,
              })}
            >
              <ArrowLeft />
            </button>
            <button
              onClick={() => swiperRef.slideNext()}
              className={cn('z-[1] hidden h-12 w-12 text-[#333] min-l:block', {
                'cursor-not-allowed opacity-30': end,
                '!opacity-0': start && end,
              })}
            >
              <ArrowRight />
            </button>
            {/* <PrevArrow swiper={swiper} start={start} end={end} />
            <NextArrow swiper={swiper} start={start} end={end} /> */}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
export default PopularProduct
